export const myLinks = [
    {
      id: 1,
      text: 'About',
      url: '#about',
    },
    {
      id: 2,
      text: 'Projects',
      url: '#projects',
    },
    {
      id: 3,
      text: 'Contact',
      url: '#contact',
    },
  ]
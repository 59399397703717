import React from "react";
import "./style.css";

import { Navbar, Container, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { myLinks } from './navData';

import GitHubIcon from '@mui/icons-material/GitHub';
import CottageIcon from '@mui/icons-material/Cottage';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export default function NavigationBar() {
  const handleClick = (e) => {//custom click handler so we can prevent titles from scrolling underneath the navbar
    e.preventDefault()
    const target = e.target.getAttribute('href')
    const location = document.querySelector(target).offsetTop

    window.scrollTo({
      left: 0,
      top: location - 64,
    })
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );
  

  return (
    
    <Navbar variant="dark" sticky="top" className="customNav border-bottom border-info">
    <Container>

    <Navbar.Brand href="#home">
        <CottageIcon className='homeIcon' style={{fontSize: '3rem'}}/>{' '}
      </Navbar.Brand>


      
      

     

      
     
      
      


      <Nav className="ml-auto">

      {myLinks.map((link) => {

        if (link.url !== '#contact')
        {
          return (
            <Nav.Link href={link.url} key={link.id} onClick={handleClick}>{link.text}</Nav.Link>
          )
        }

        if (link.url == '#contact')
        {
          return (
            <Nav.Link href={link.url} key={link.id} onClick={handleClick}>{link.text} Me<MailOutlineIcon style={{marginLeft: '.25rem'}}/></Nav.Link>
          )
        }
        

 
          

        })
      }


      <OverlayTrigger placement="bottom"  overlay={renderTooltip('Note: This will open a new tab!')}>
        <Nav.Link href="https://www.github.com/FrankBotos" target="_blank">My Github<GitHubIcon style={{marginLeft: '.25rem'}} /></Nav.Link>
      </OverlayTrigger>

      

      </Nav>

      
      

        

      
        
        
      
      

    </Container>
  </Navbar>
  
  );
}
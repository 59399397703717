import React, { useRef } from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Button } from 'react-bootstrap';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import AcUnitTwoToneIcon from '@mui/icons-material/AcUnitTwoTone';
import ShareTwoToneIcon from '@mui/icons-material/ShareTwoTone';
import BrushTwoToneIcon from '@mui/icons-material/BrushTwoTone';
import SupportAgentTwoToneIcon from '@mui/icons-material/SupportAgentTwoTone';
import PsychologyTwoToneIcon from '@mui/icons-material/Psychology';

import NavigationBar from './NavigationBar';

import emailjs from '@emailjs/browser';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function App() {
  const notify = (notice) => toast(notice);

  const handleClick = (e) => {
    //custom click handler so we can prevent titles from scrolling underneath the navbar
    e.preventDefault();
    const target = e.target.getAttribute('href');
    const location = document.querySelector(target).offsetTop;

    window.scrollTo({
      left: 0,
      top: location - 64,
    });
  };

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_e2ixjt9', 'template_zzbn4fb', form.current, 'user_9GL61iw8D4XX6Z8V29Sgv')
      .then((result) => {
          //console.log(result.text);
          notify("Your message was successfully sent! Keep your eyes open for a response soon!");
          e.target.reset();
      }, (error) => {
          //console.log(error.text);
          notify("Could not send message! Please try again!");
      });
      
  };

  

  return (
    <main id="home">
      <NavigationBar />
      <ToastContainer />

      <section className="home">
        <div className="mainBackdrop">
          <div style={{ paddingTop: '30vh' }}>
            <pre className="titleColor">
              00110001
              <br />
              00110011
              <br />
              00110011
              <br />
              00110111
            </pre>
            <br />
            <br />
            <h1 className="display-3 titleColor">
              <b>Hello, my name is <span className="nameColor">Frank Botos</span>,</b>
            </h1>
            <p className="display-6 titleColor">
              <b>I am a software developer with a wide range of technical skills. Let's make something.</b>
            </p>
            <br />
            <br />
            <Button
              variant="outline-info"
              size="lg"
              href="#about"
              style={{ padding: '1.2rem', color: 'white' }}
              onClick={handleClick}
            >
              Check out my resume <ArrowDropDownCircleIcon />
            </Button>
          </div>
        </div>
      </section>

      <section className="about" id="about">
        

        <div className="container"><br/>
        <h1 className='display-4 text-center'>About</h1>
        
        
        <div style={{paddingBottom: '2rem'}}></div>
            <hr className="my-4" />
            <div style={{paddingTop: '5rem'}}></div>




          <div className="row">

            
            
          
            

            

            <div className="col">
              <img src={require('./images/profilepic.png')} className="croppedImage" />

              <div><br/>
                <h1 className="display-6 text-center">My story...</h1>
                <span className="lead">
                  Software development has been my passion since I first witnessed "Super Mario Bros" in the mid-90's being played on a bootleg system. Since then, I have been on a quest, building a toolbox of practical experience and creativity. I enjoy making beautiful, functional software.
                </span>
              </div>
            </div>

            <div className="col-sm-4" style={{paddingLeft: '5rem', width: '40rem'}}>
              <br/><br/>
                
                <span className="lead">

                  <div className='text-center'>
                  <PsychologyTwoToneIcon style={{fontSize: '4rem'}}/> Seneca College, "Computer Programming" <hr/> 
                  </div>

                <div className="text-center">
                <small className="text-muted">3.6GPA on a scale of 4.0, graduated with honours</small>
                </div>
                </span>
                <hr/> 
                
              
                <ShareTwoToneIcon style={{fontSize: '2rem'}}/> <span className="lead">Javascript (React, node.js, various libraries)</span><br/>
                <ShareTwoToneIcon style={{fontSize: '2rem'}}/> <span className="lead">HTML5/CSS</span><br/>
                <ShareTwoToneIcon style={{fontSize: '2rem'}}/> <span className="lead">Databases (Firebase, MongoDB, SQL, SQLite)</span><br/>
                <ShareTwoToneIcon style={{fontSize: '2rem'}}/> <span className="lead">Unity, Android Studio</span><br/>
                <ShareTwoToneIcon style={{fontSize: '2rem'}}/> <span className="lead">C++, Java, C#</span><br/><br/>

                <BrushTwoToneIcon style={{fontSize: '2rem'}}/> <span className="lead">Blender 3D</span><br/>
                <BrushTwoToneIcon style={{fontSize: '2rem'}}/> <span className="lead">GIMP/Krita</span><br/><br/>

                <SupportAgentTwoToneIcon style={{fontSize: '2rem'}}/> <span className="lead">Good communication skills</span><br/>
                <SupportAgentTwoToneIcon style={{fontSize: '2rem'}}/> <span className="lead">Experience in a client-facing role</span><br/>
                <SupportAgentTwoToneIcon style={{fontSize: '2rem'}}/> <span className="lead">Fast learner of new systems</span>


                
              
            </div>

            
            <div className='row' style={{paddingTop: '4rem'}}>
            <div className="col text-center lead" style={{fontSize: '1.5rem'}}><AcUnitTwoToneIcon className="snowAnim" style={{fontSize: '5rem'}}/><br/>Growth Mindset.</div>
            <div className="col text-center lead" style={{fontSize: '1.5rem'}}><AcUnitTwoToneIcon className="snowAnim" style={{fontSize: '5rem'}}/><br/>Exposure to a plethora of tech-stacks.</div>
            <div className="col text-center lead" style={{fontSize: '1.5rem'}}><AcUnitTwoToneIcon className="snowAnim" style={{fontSize: '5rem'}}/><br/>Deeply care about the aesthetics of my work.</div>
            <div className="col text-center lead" style={{fontSize: '1.5rem'}}><AcUnitTwoToneIcon className="snowAnim" style={{fontSize: '5rem'}}/><br/>Familiarity with common design patterns.</div>
            </div>
            


          </div>
        </div><br/><br/>
      </section>

      <section className="projects" id="projects">
        <div className="container">
          <h1 className='display-4 text-center'>My Projects</h1>
            
            <div style={{paddingBottom: '5rem'}}></div>
            <hr className="my-4" />
            <div style={{paddingTop: '5rem'}}></div>

            <div className='row'>
            <div className='col d-flex align-items-stretch'>
              <div className="card border border-primary">
                <div className="card-header">
                  React/Express/Firebase
                </div>
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title">Trello Clone</h5>
                  <p className="card-text">With a focus on SaaS fundamentals, I worked on a bare bones Trello clone with full drag and drop functionality, integration with Google OAuth, and real time database synchronization.</p>
                  <p className="card-text">Please feel free to click through and play around with the application!</p>
                </div>
                <div className="text-center"><a href="https://trelloclone-d93a5.web.app/" target="_blank" className="btn btn-success">View Live Project</a></div><br/>

              </div>
            </div>

            <div className='col d-flex align-items-stretch'>
              <div className="card border border-primary">
                <div className="card-header">
                  C++
                </div>
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title">Sockets, File Transfer</h5>
                  <p className="card-text">Wrote a C++ client/server from scratch, and successfully transfered files across the internet, as well as a local network, while maintaining file integrity.</p>
                  
                </div>
                <div className="text-center"><a href="https://github.com/FrankBotos/CPP-SocketsFileTransfer" target="_blank" className="btn btn-primary">View on GitHub</a></div><br/>

              </div>
            </div>

            <div className='col d-flex align-items-stretch'>
              <div className="card border border-primary">
                <div className="card-header">
                  C++
                </div>
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title">Poker Hand Strength Calculator</h5>
                  <p className="card-text">A project mainly pursued out of curiosity. Simulates 1000 (or more) poker games to find the percentage of winning. Features a basic implementation of threading.</p>
                  
                </div>
                <div className="text-center"><a href="https://github.com/FrankBotos/PokerHandStrength" target="_blank" className="btn btn-primary">View on GitHub</a></div><br/>
              </div>
            </div>
            </div>
            
          

          <div className='row'>

          

            <div className='col d-flex align-items-stretch'>
              <div className="card border border-primary">
                <div className="card-header">
                  React/Firebase
                </div>
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title">Personal Project - Untitled Full Stack Application</h5>
                  <p className="card-text">Leveraging ReactJS/Firebase, I am currently in the process of building a full stack application with features such as REST API calls, user accounts, and some social features relying on CRUD operations. About 80% complete.</p>
                  
                </div>
                <div className="text-center"><a href="#" className="btn btn-secondary disabled">Coming Soon!</a></div><br/>
              </div>
            </div>

            <div className='col d-flex align-items-stretch'>
              <div className="card border border-primary">
                <div className="card-header">
                  Unity/Blender3D
                </div>
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title">Personal Project - Untitled Video Game</h5>
                  <p className="card-text">Building all gameplay systems, and designing all 3D models from scratch. About 25% complete.</p>
                  
                </div>
                <div className="text-center"><a href="#" className="btn btn-secondary disabled">Coming Soon!</a></div><br/>
              </div>
            </div>

          
          </div>
          <br/><br/>
        </div>
      </section>

      <section className="contact" id="contact">
        <div className="container" style={{width: '40%'}}><br />
          <h1 className='display-4 text-center'>Contact Me</h1>
          
          
          
          <div style={{paddingBottom: '5rem'}}></div>
          <hr className="my-4" />
          <div style={{paddingTop: '5rem'}}></div>



          <form ref={form} onSubmit={sendEmail}>
            <div className="form-group text-center lead">





              <label>Your E-Mail:</label>
              <input type="email" className="form-control" name="from_name" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
              

              <br />
              <label>Your Message:</label>
              <textarea className="form-control" name="message" id="exampleFormControlTextarea1" rows="15" placeholder="Have a project in mind? Drop me a line!"></textarea>

              <br />
              <button className='btn btn-info btn-lg'type="submit" value="Send" style={{marginRight: '1rem', color: 'white', marginBottom: '2rem'}}>Send</button>
              <button className='btn btn-warning btn-lg' type="reset" value="Reset" style={{marginLeft: '1rem', color: 'white',marginBottom: '2rem'}}>Reset</button>
            </div>
          </form>





        </div>
      </section>

      <section className="rest">
        
          <div style={{textAlign: 'center', color: 'white'}}><br/><b>© 2022 Copyright: Frank Botos</b>
          </div><br/>



        
      </section>
    </main>
    
  );
}